import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { HttpClientService } from "./http-client.service";
import { AuthenticationService } from "./authentication.service";
import { TruckManagerLayoutService } from "./truck-manager-layout.service";
import { NotificationService } from "./notification-service";
import { AddressBookItem } from "../model/address-book-item.object";
import { AddressFavorite } from "../model/address-favorite.object";
import { Person } from "../model/person.object";
import { AddressBookItemCar } from "../model/address-book-item-car";
import { AddressBookItemAttachment } from "../model/address-book-item-attachment";
import { IS_DEMO, ServiceConfiguration } from "../config";
import { connections } from "../connect";
import { AddressBookItemPricelist } from "../model/address-book-item-pricelist";


@Injectable({
  providedIn: 'root',
})
export class AddressBookService {

  private _addressItems: BehaviorSubject<Array<AddressBookItem>> = new BehaviorSubject([]);
  private _addressItemsCache: Array<AddressBookItem> = [];
  private _loadingAddressBookItems: boolean = false;

  private _filterNames: Array<string> = [
    'filter',
    'car_loc',
    'car_type',
    'car_size',
    'gps',
    'dist',
    'often_used'
  ];

  private _sortNames: Array<string> = [
    'company',
    'cin',
    'tin',
    'country',
    'city',
    'street',
    'zip',
    'updated'
  ];

  constructor(
    private _httpClient: HttpClient,
    private _http: HttpClientService,
    private _layoutServ: TruckManagerLayoutService,
    private _authService: AuthenticationService,
    private _notificationService: NotificationService
  ) {
  }

  get loadingAddressBookItems(): boolean {
    return this._loadingAddressBookItems;
  }

  private _loadingPersons: boolean = false;
  get loadingPersons(): boolean {
    return this._loadingPersons;
  }

  private _loadingFavorites: boolean = false;
  get loadingFavorites(): boolean {
    return this._loadingFavorites;
  }

  private _loadingWarehouses: boolean = false;
  get loadingWarehouses(): boolean {
    return this._loadingWarehouses;
  }

  private _loadingLines: boolean = false;
  get loadingLines(): boolean {
    return this._loadingLines;
  }

  private _loadingVies: boolean = false;
  public get loadingVies(): boolean {
    return this._loadingVies;
  }

  private _loadingTax: boolean = false;
  public get loadingTax(): boolean {
    return this._loadingTax;
  }
  
  private clearAddressBookItemsCache(): void {
    this._addressItemsCache = [];
  }

  // flag that is set exactly before .next() of observable in get 
  // (to catch it in address-book-item correctly)
  private _loadedNow: boolean = false;
  public get loadedNow(): boolean {
    return this._loadedNow;
  }
  public set loadedNow(value: boolean) {
    this._loadedNow = value;
  }
  
  // method for creating url string for filtering
  filterUrl(filterObj: any) {
    let result = '';
    let filterKeys: Array<string> = Object.keys(filterObj);
    filterKeys.forEach(
      key => {
        // check possible filters for obligations
        if (this._filterNames.includes(key)) {
          result += '&' + key + '=' + encodeURIComponent(filterObj[key]);
        }
      }
    );
    return result;
  }
  
  // method for creating url string for sorting
  sortUrl(sortObj: any) {
    let result = '';
    let sortKeys: Array<string> = Object.keys(sortObj);
    sortKeys.forEach(
      key => {
        // check possible sorts for obligations (should be always only one sort param)
        if (this._sortNames.includes(key)) {
          result += '&sort=' + key + ',' + sortObj[key];
        }
      }
    );
    return result;
  }


  /*********************************************************/
  /* Address book items methods */
  /*********************************************************/
  // GET address-book - autocompleters version
  getAddressBookItems(searchText: string, often_order_used: boolean = false): Observable<Array<AddressBookItem>> {
    this.clearAddressBookItemsCache();

    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      let url: string = ServiceConfiguration.addressBook.api;

      // skip long strings <=> no requests
      if (searchText.length > 15) {
        this._addressItems.next([]);
        return this._addressItems.asObservable();
      }
      // check if text is empty (default) or atleast 1 char(filter)
      if (searchText.length > 0) {
        // to lower and add filter string
        searchText = searchText.toLowerCase();
        url += '?filter=' + encodeURIComponent(searchText);
      }
      else {
        if (often_order_used) {
          url += '?often_order_used=true'
        }
        else {
          url += '?often_used=true';
        }
      }

      this._loadingAddressBookItems = true;
      this._loadedNow = false;
      this._http.get(url).subscribe(
        response => {
          this._addressItemsCache = this.buildAddressBookItemsFromData(response);
          // slice number of items to max constant value 10
          if (this._addressItemsCache.length > 10) {
            this._addressItemsCache = this._addressItemsCache.slice(0, 10);
          }
          this._loadedNow = true;
          this._loadingAddressBookItems = false;
          this._addressItems.next(this._addressItemsCache);
        },
        error => {
          // handle error
          console.log(error);
          this._addressItemsCache = [];
          this._loadedNow = true;
          this._loadingAddressBookItems = false;
          this._addressItems.next(this._addressItemsCache);
        }
      );
    }

    return this._addressItems.asObservable();
  }

  // GET address-book - managing (ta2-search component)
  getAddressBookItemsFull(page: number = 0, size: number = 0, filterObj: any, sortObj: any, often_order_used: boolean = false): Observable<Array<AddressBookItem>> {
    // this.clearAddressBookItemsCache();
    let result: BehaviorSubject<Array<AddressBookItem>> = new BehaviorSubject(null);

    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      let url: string = ServiceConfiguration.addressBook.api;
      // set page
      url += '?page=' + page; // + 0;
      // set page size
      url += '&size=' + size; // + 5000

      if (filterObj) {
        // skip long strings <=> no requests
        if (filterObj.search_text && filterObj.search_text.length > 15) {
          // this._addressItems.next([]);
          // return this._addressItems.asObservable();
          result.next([]);
          return result.asObservable();
        }
        // if (!filterObj.search_text) {
        //   if (often_order_used) {
        //     url += '&often_order_used=true'
        //   }
        //   else {
        //     url += '&often_used=true';
        //   }
        // }
        
        // any filters were defined
        url += this.filterUrl(filterObj);
      }

      // any sort parameters were defined
      if (sortObj) {
        if (sortObj) url += this.sortUrl(sortObj);
      }

      this._loadingAddressBookItems = true;
      this._loadedNow = false;
      this._http.get(url).subscribe(
        response => {
          this._loadedNow = true;
          this._loadingAddressBookItems = false;
          // this._addressItemsCache = this.buildAddressBookItemsFromData(response);
          // this._addressItems.next(this._addressItemsCache);
          result.next(this.buildAddressBookItemsFromData(response));
        },
        error => {
          // handle error
          console.log(error);
          this._loadedNow = true;
          this._loadingAddressBookItems = false;
          result.next([]);
          // this._addressItemsCache = [];
          // this._addressItems.next(this._addressItemsCache);
        }
      );
    }

    return result.asObservable();
    // return this._addressItems.asObservable();
  }

  // GET address-book - without cache array
  getAddressBookItemsNoObservers(searchText: string, often_order_used: boolean = false): Observable<Array<AddressBookItem>> {
    let result: BehaviorSubject<Array<AddressBookItem>> = new BehaviorSubject([]);

    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      let url: string = ServiceConfiguration.addressBook.api;
      
      // skip long strings <=> no requests
      if (searchText.length > 15) {
        result.next([]);
        return result.asObservable();
      }
      // check if text is empty (default) or atleast 1 char(filter)
      if (searchText.length > 0) {
        // to lower and add filter string
        searchText = searchText.toLowerCase();
        url += '?filter=' + encodeURIComponent(searchText);
      }
      else {
        if (often_order_used) {
          url += '?often_order_used=true'
        }
        else {
          url += '?often_used=true';
        }
      }

      this._loadingAddressBookItems = true;
      this._loadedNow = false;
      this._http.get(url).subscribe(
        response => {
          let arr = this.buildAddressBookItemsFromData(response);
          this._loadedNow = true;
          this._loadingAddressBookItems = false;
          result.next(arr);
        },
        error => {
          // handle error
          console.log(error);
          this._loadedNow = true;
          this._loadingAddressBookItems = false;
          result.next([]);
        }
      );
    }

    return result.asObservable();
  }

  // POST address-book
  createAddressBookItem(item: AddressBookItem): Observable<AddressBookItem> {
    let newItem: BehaviorSubject<AddressBookItem> = new BehaviorSubject(null);

    if (item && (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated()))) {
      this._http.post(ServiceConfiguration.addressBook.api, item.apiObject).subscribe(
        response => {
          let bookItem: AddressBookItem = this.buildAddressBookItem(response);
          newItem.next(bookItem);
          
          // success alert
          let alertSuccess: string = $localize`Firma %Item byla úspěšně uložena do adresáře.`;
          alertSuccess = alertSuccess.replace('%Item', item.company);
          this._notificationService.alert(alertSuccess, 'success', 4000);
        },
        error => {
          //handle error
          console.error(error);
          // error alert
          let alertError: string = $localize`Chyba při ukládání firmy.`;
          this._notificationService.alert(alertError, 'error', 4000);
        }
      );
    }

    return newItem.asObservable();
  }

  // PUT address-book
  updateAddressBookItem(item: AddressBookItem): Observable<AddressBookItem> {
    let updatedItem: BehaviorSubject<AddressBookItem> = new BehaviorSubject(null);

    if (item && item.book_key && (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated()))) {
      let url: string = ServiceConfiguration.addressBook.apiKey;
      url = url.replace('<BOOK_KEY>', item.book_key.toString());

      this._http.put(url, item.apiObject).subscribe(
          response => {
            let bookItem: AddressBookItem = this.buildAddressBookItem(response);
            updatedItem.next(bookItem);
            
            // success alert
            let alertSuccess: string = $localize`Firma %Item byla úspěšně upravena v adresáři.`;
            alertSuccess = alertSuccess.replace('%Item', item.company);
            this._notificationService.alert(alertSuccess, 'success', 4000);
          },
          error => {
            //handle error
            console.error(error);
            // error alert
            let alertError: string = $localize`Chyba při ukládání úpravy firmy.`;
            this._notificationService.alert(alertError, 'error', 4000);
          }
        );
    }

    return updatedItem.asObservable();
  }

  // DELETE address-book
  deleteAddressBookItem(item: AddressBookItem): Observable<boolean> {
    let res: BehaviorSubject<any> = new BehaviorSubject(false);
    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      let url: string = ServiceConfiguration.addressBook.apiKey;
      url = url.replace('<BOOK_KEY>', item.book_key.toString());

      this._http.delete(url).subscribe(
        response => {
          let bookItem: AddressBookItem = this.buildAddressBookItem(response);
          res.next(bookItem);

          // success alert
          let alertSuccess: string = $localize`Firma %Item byla úspěšně odstraněna z adresáře.`;
          alertSuccess = alertSuccess.replace('%Item', item.company);
          this._notificationService.alert(alertSuccess, 'success', 4000);
        },
        error => {
          //handle error
          console.error(error);
          // error alert
          let alertError: string = $localize`Chyba při odstranění firmy %Item`;
          alertError = alertError.replace('%Item', item.company);
          if (error.status == 409) {
            // conflict
            alertError += $localize` - pro firmu byla vytvořena zakázka, objednávka či faktura.`;
          }
          else {
            // other error
            alertError += $localize` - kontaktujte podporu`;
          }
          this._notificationService.alert(alertError, 'error', 4000);
        }
      );
    }
    return res.asObservable();
  }

  // method for creating address book item array
  private buildAddressBookItemsFromData(data: Array<any>): any {
    let items: Array<AddressBookItem> = [];
    data.forEach(
      i => {
        let item = this.buildAddressBookItem(i);
        items.push(item);
      }
    );
    return items;
  }

  // method for creating a single address book item object
  private buildAddressBookItem(i: any): AddressBookItem {
    let item: AddressBookItem = new AddressBookItem();
    for (let key in i) {
      item[key] = i[key];
    }
    return item;
  }



  /*********************************************************/
  /* Contacts/Persons methods */
  /*********************************************************/
  // TODO getContacts by šlo předělat pouze tak, aby se kontakty vždy načetly po vyberu/změně book_key
  // nyní se zbytečně dělá novy request po kazdem tuknuti..

  // GET /address-book/<KEY>/persons/ - autocompleter version
  getContacts(book_key: number, partialMatch: string = null): Observable<Array<Person>> {
    let result: BehaviorSubject<Array<Person>> = new BehaviorSubject([]);

    if (book_key && (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated()))) {
      let url: string = ServiceConfiguration.addressBook.apiPersons;
      url = url.replace('<BOOK_KEY>', book_key.toString());

      this._loadingPersons = true;
      this._http.get(url).subscribe(
        response => {
          let persons: Array<Person> = this.buildPersonsFromData(response);

          if (partialMatch) {
            persons = persons.filter(p => 
              p.name.toLocaleLowerCase().indexOf(partialMatch.toLowerCase()) > -1 ||
              p.email.toLocaleLowerCase().indexOf(partialMatch.toLowerCase()) > -1 ||
              p.phone.indexOf(partialMatch) > -1 || p.mobile.indexOf(partialMatch) > -1
            );
            persons.sort((a, b) => (a.name > b.name) ? 1 : -1);
          }
          // slice number of cities to max constant value 5
          if (persons.length > 5) {
            persons = persons.slice(0, 6);
          }
          this._loadingPersons = false;
          result.next(persons);
        },
        error => {
          // handle error
          console.log(error);
          this._loadingPersons = false;
        }
      );
    }

    return result.asObservable();
  }

  // GET /address-book/<KEY>/persons/ - processing in component
  getContactsToComponent(book_key: number): Observable<any> {
    if (book_key && (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated()))) {
      let url: string = ServiceConfiguration.addressBook.apiPersons;
      url = url.replace('<BOOK_KEY>', book_key.toString());

      return this._http.get(url);
    }
  }

  // POST /address-book/<KEY>/persons/
  createPerson(book_key: number, person: Person): Observable<Person> {
    let newPerson: BehaviorSubject<Person> = new BehaviorSubject(null);

    if (book_key && (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated()))) {
      let url: string = ServiceConfiguration.addressBook.apiPersons;
      url = url.replace('<BOOK_KEY>', book_key.toString());

      this._http.post(url, person.apiObject).subscribe(
        response => {
          let person: Person = this.buildPerson(response);
          newPerson.next(person);
        },
        error => {
          //handle error
          console.error(error);
          this._notificationService.alert(
            $localize`Chyba při vytváření kontaktu - ` + person.name, 'error', 4000
          );
        }
      );
    }

    return newPerson.asObservable();
  }

  // PUT /address-book/<KEY>/persons/<PERSON_KEY>
  updatePerson(book_key: number, person: Person): Observable<Person> {
    let updatedPerson: BehaviorSubject<Person> = new BehaviorSubject(null);

    if (book_key && (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated()))) {
      let url: string = ServiceConfiguration.addressBook.apiPersonsKey;
      url = url.replace('<BOOK_KEY>', book_key.toString());
      url = url.replace('<PERSON_KEY>', person.person_key.toString());

      this._http.put(url, person.apiObject).subscribe(
        response => {
          let person: Person = this.buildPerson(response);
          updatedPerson.next(person);
        },
        error => {
          //handle error
          console.error(error);
          this._notificationService.alert(
            $localize`Chyba při úpravě kontaktu - ` + person.name, 'error', 4000
          );
        }
      );
    }

    return updatedPerson.asObservable();
  }

  // DELETE /address-book/<KEY>/persons/<PERSON_KEY>
  deletePerson(book_key: number, person: Person): Observable<any> {
    let res: BehaviorSubject<any> = new BehaviorSubject(null);
    if (book_key && (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated()))) {
      let url: string = ServiceConfiguration.addressBook.apiPersonsKey;
      url = url.replace('<BOOK_KEY>', book_key.toString());
      url = url.replace('<PERSON_KEY>', person.person_key.toString());

      this._http.delete(url).subscribe(
        response => {
          res.next(true);
          this._notificationService.alert(
            $localize`Kontakt - ` + person.name + $localize` byl odstraněn.`, 'success', 4000
          );
        },
        error => {
          //handle error
          console.error(error);
          this._notificationService.alert(
            $localize`Chyba při odstranění kontaktu - ` + person.name, 'error', 4000
          );
        }
      );
    }
    return res.asObservable();
  }

  // method for creating persons array
  public buildPersonsFromData(data: Array<any>): Array<Person> {
    let persons: Array<Person> = [];
    data.forEach(
      p => {
        let person: Person = this.buildPerson(p);
        persons.push(person);
      }
    );
    return persons;
  }

  // method for creating a single parson object
  public buildPerson(o: any): Person {
    let person: Person = new Person();
    for (let key in o) {
      person[key] = o[key];
    }
    return person;
  }

  
  /*********************************************************/
  /* Address - Cars / Destinations */
  /*********************************************************/
  private _loadingCarDests: boolean = false;
  public get loadingCarDests(): boolean {
    return this._loadingCarDests;
  }

  // GET /address-book/<BOOK_KEY>/car-dests/
  getBookItemCarDests(book_key: number): Observable<Array<AddressBookItemCar>> {
    let result: BehaviorSubject<Array<AddressBookItemCar>> = new BehaviorSubject(null);

    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      // default url without any filter
      let url: string = ServiceConfiguration.addressBook.apiCarDests;
      url = url.replace('<BOOK_KEY>', book_key.toString());

      this._http.get(url).subscribe(
        response => {
          if (response) {
            let car_dests = this.buildCarDestsArray(response);
            result.next(car_dests);
          }
        },
        error => {
          console.log(error);
        }
      );
    }

    return result.asObservable();
  }
  
  // POST /address-book/<BOOK_KEY>/car-dests/
  createBookItemCarDest(book_key: number, o: AddressBookItemCar): Observable<AddressBookItemCar> {
    let result: BehaviorSubject<AddressBookItemCar> = new BehaviorSubject(null);

    if (this._authService.isAuthenticated()) {
      // initialize data
      let data = o.apiObject;
      let url: string = ServiceConfiguration.addressBook.apiCarDests;
      url = url.replace('<BOOK_KEY>', book_key.toString());
      console.log(data);

      this._http.post(url, data).subscribe(
        response => {
          // alert
          let alertSuccess: string = $localize`Vozidla zákazníka/dopravce byly úspěšně vytvořeny.`;
          this._notificationService.alert(alertSuccess, 'success', 4000);
          // observable next
          result.next(this.buildCarDest(response));
        },
        error => {
          console.log(error);
          // alert
          let alertError: string = $localize`Chyba při vytváření vozidel zákazníka/dopravce.`;
          this._notificationService.alert(alertError, 'error', 4000);
        }
      );
    }

    return result.asObservable();
  }
  
  // PUT /address-book/<BOOK_KEY>/car-dests/<DEST_KEY>
  updateBookItemCarDest(book_key: number, o: AddressBookItemCar): Observable<AddressBookItemCar> {
    let result: BehaviorSubject<AddressBookItemCar> = new BehaviorSubject(null);
    
    if (this._authService.isAuthenticated()) {
      // initialize data
      let data = o.apiObject;
      let url: string = ServiceConfiguration.addressBook.apiCarDestsKey;
      url = url.replace('<BOOK_KEY>', book_key.toString());
      url = url.replace('<DEST_KEY>', o.book_dest_key.toString());
      console.log(data);

      this._http.put(url, data).subscribe(
        response => {
          // alert
          let alertSuccess: string = $localize`Vozidla zákazníka/dopravce byly úspěšně upraveny.`;
          this._notificationService.alert(alertSuccess, 'success', 4000);
          // observable next
          result.next(this.buildCarDest(response));
        },
        error => {
          console.log(error);
          // alert
          let alertError: string = $localize`Chyba při úpravě vozidel zákazníka/dopravce.`;
          this._notificationService.alert(alertError, 'error', 4000);
        }
      );
    }

    return result.asObservable();
  }

  // DELETE /address-book/<BOOK_KEY>/car-dests/<DEST_KEY>
  deleteBookItemCarDest(book_key: number, o: AddressBookItemCar): Observable<any> {
    let result: BehaviorSubject<any> = new BehaviorSubject(null);
    
    if (this._authService.isAuthenticated()) {
      // initialize data
      let url: string = ServiceConfiguration.addressBook.apiCarDestsKey;
      url = url.replace('<BOOK_KEY>', book_key.toString());
      url = url.replace('<DEST_KEY>', o.book_dest_key.toString());

      this._http.delete(url).subscribe(
        response => {
          console.log(response);
          // alert
          let alert: string = $localize`Vozidla zákazníka/dopravce byly úspěšně odstraněny.`;
          this._notificationService.alert(alert, 'success', 4000);
          result.next(true);
        },
        error => {
          console.log(error);
          // alert
          let alert: string = $localize`Chyba při odstranění vozidel zákazníka/dopravce.`; 
          this._notificationService.alert(alert, 'error', 4000);
        }
      );
    }
    return result.asObservable();
  }

  // method for creating car dests array
  private buildCarDestsArray(data: Array<any>): any {
    let car_dests: Array<AddressBookItemCar> = [];
    data.forEach(
      o => {
        let car_dest: AddressBookItemCar = this.buildCarDest(o);
        car_dests.push(car_dest);
      }
    );
    return car_dests;
  }

  // method for creating a single car dest address object
  private buildCarDest(o: any): AddressBookItemCar {
    let car_dest: AddressBookItemCar = new AddressBookItemCar();
    for (let key in o) {
      car_dest[key] = o[key];
    }
    return car_dest;
  }


  /*********************************************************/
  /* Address - Attachments */
  /*********************************************************/
  // GET /address-book/<BOOK_KEY>/attachment/
  getAttachments(book: AddressBookItem): Observable<Array<AddressBookItemAttachment>> {
    let result: BehaviorSubject<Array<AddressBookItemAttachment>> = new BehaviorSubject(null);

    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      // default url without any filter
      let url: string = ServiceConfiguration.addressBook.apiAttachment;
      url = url.replace('<BOOK_KEY>', book.book_key.toString());

      this._http.get(url).subscribe(
        response => {
          if (response) {
            let attachments = this.buildAttachmentsArray(response);
            result.next(attachments);
          }
        },
        error => {
          console.log(error);
        }
      );
    }

    return result.asObservable();
  }

  // POST /address-book/<BOOK_KEY>/attachment/
  createAttachment(book: AddressBookItem, attachment: AddressBookItemAttachment, file: any): Observable<any> {
    let url: string = ServiceConfiguration.addressBook.apiAttachment;
    url = url.replace('<BOOK_KEY>', book.book_key.toString());

    let formData = new FormData();
    formData.append('data', JSON.stringify(attachment.apiObject));
    formData.append('file', file);

    return this._http.post(url, formData);
  }

  // PUT /address-book/<BOOK_KEY>/attachment/<ATTACHMENT_KEY>
  updateAttachment(book: AddressBookItem, attachment: AddressBookItemAttachment): Observable<any> {
    let result: BehaviorSubject<any> = new BehaviorSubject(null);
    
    if (this._authService.isAuthenticated()) {
      let url: string = ServiceConfiguration.addressBook.apiAttachmentKey;
      url = url.replace('<BOOK_KEY>', book.book_key.toString());
      url = url.replace('<ATTACHMENT_KEY>', attachment.attach_key.toString());
      
      this._http.put(url, attachment.apiObject).subscribe(
        response => {
          console.log(response);
          this._notificationService.alert(
            $localize`Dokument byl úspěšně upraven.`, 'success', 4000
          );
        },
        error => {
          console.log(error);
          this._notificationService.alert(
            $localize`Chyba při úpravě dokumentu.`, 'success', 4000
          );
        }
      );
    }
    return result.asObservable();
  }

  // DELETE /address-book/<BOOK_KEY>/attachment/<ATTACHMENT_KEY>
  deleteAttachment(book: AddressBookItem, attachment: AddressBookItemAttachment): Observable<boolean> {
    let res: BehaviorSubject<any> = new BehaviorSubject(false);
    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      let url: string = ServiceConfiguration.addressBook.apiAttachmentKey;
      url = url.replace('<BOOK_KEY>', book.book_key.toString());
      url = url.replace('<ATTACHMENT_KEY>', attachment.attach_key.toString());

      this._http.delete(url).subscribe(
        response => {
          this._notificationService.alert(
            $localize`Dokument byl odstraněn.`, 'success', 4000
          );
          res.next(true);
        },
        error => {
          //handle error
          console.error(error);
          this._notificationService.alert(
            $localize`Chyba při odstranění dokumentu - kontaktujte podporu.`, 'error', 4000
          );
        }
      );
    }
    return res.asObservable();
  }
  
  // method for creating car dests array
  private buildAttachmentsArray(data: Array<any>): any {
    let attachments: Array<AddressBookItemAttachment> = [];
    data.forEach(
      o => {
        let attachment: AddressBookItemAttachment = this.buildAttachment(o);
        attachments.push(attachment);
      }
    );
    return attachments;
  }

  // method for creating a single car dest address object
  public buildAttachment(o: any): AddressBookItemAttachment {
    let attachment: AddressBookItemAttachment = new AddressBookItemAttachment();
    for (let key in o) {
      attachment[key] = o[key];
    }
    return attachment;
  }

  // method for downloading/opening attachment of invoice
  getAttachment(attachment_link: string): Observable<Blob> {
    // "https://app2.truckmanager.eu/nettedev/api/v1/files/company/52135-Objednavka1.pdf?name=Objednavka1.pdf"
    return this._http.get(connections.apiUrl + encodeURI(attachment_link), {responseType: 'blob'});
  }


  /*********************************************************/
  /* Address - Pricelists */
  /*********************************************************/
  private _loadingPricelist: boolean = false;
  public get loadingPricelist(): boolean {
    return this._loadingPricelist;
  }

  // GET /address-book/<BOOK_KEY>/pricelist/
  getBookItemPricelist(book_key: number): Observable<Array<AddressBookItemPricelist>> {
    let result: BehaviorSubject<Array<AddressBookItemPricelist>> = new BehaviorSubject(null);

    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      // default url without any filter
      let url: string = ServiceConfiguration.addressBook.apiPricelist;
      url = url.replace('<BOOK_KEY>', book_key.toString());

      this._http.get(url).subscribe(
        response => {
          if (response) {
            let pricelists = this.buildPricelistsArray(response);
            result.next(pricelists);
          }
        },
        error => {
          console.log(error);
        }
      );
    }

    return result.asObservable();
  }
  
  // POST /address-book/<BOOK_KEY>/pricelist/
  createBookItemPricelist(book_key: number, o: AddressBookItemPricelist): Observable<AddressBookItemPricelist> {
    let result: BehaviorSubject<AddressBookItemPricelist> = new BehaviorSubject(null);

    if (this._authService.isAuthenticated()) {
      // initialize data
      let data = o.apiObject;
      let url: string = ServiceConfiguration.addressBook.apiPricelist;
      url = url.replace('<BOOK_KEY>', book_key.toString());
      console.log(data);

      this._http.post(url, data).subscribe(
        response => {
          // alert
          let alertSuccess: string = $localize`Ceník zákazníka/dopravce byl úspěšně vytvořen.`;
          this._notificationService.alert(alertSuccess, 'success', 4000);
          // observable next
          result.next(this.buildPricelist(response));
        },
        error => {
          console.log(error);
          // alert
          let alertError: string = $localize`Chyba při vytváření ceníku zákazníka/dopravce.`;
          this._notificationService.alert(alertError, 'error', 4000);
        }
      );
    }

    return result.asObservable();
  }
  
  // PUT /address-book/<BOOK_KEY>/pricelist/<PRICELIST_KEY>
  updateBookItemPricelist(book_key: number, o: AddressBookItemPricelist): Observable<AddressBookItemPricelist> {
    let result: BehaviorSubject<AddressBookItemPricelist> = new BehaviorSubject(null);
    
    if (this._authService.isAuthenticated()) {
      // initialize data
      let data = o.apiObject;
      let url: string = ServiceConfiguration.addressBook.apiPricelistKey;
      url = url.replace('<BOOK_KEY>', book_key.toString());
      url = url.replace('<PRICELIST_KEY>', o.pricelist_key.toString());
      console.log(data);

      this._http.put(url, data).subscribe(
        response => {
          // alert
          let alertSuccess: string = $localize`Ceník zákazníka/dopravce byl úspěšně upraven.`;
          this._notificationService.alert(alertSuccess, 'success', 4000);
          // observable next
          result.next(this.buildPricelist(response));
        },
        error => {
          console.log(error);
          // alert
          let alertError: string = $localize`Chyba při úpravě ceníku zákazníka/dopravce.`;
          this._notificationService.alert(alertError, 'error', 4000);
        }
      );
    }

    return result.asObservable();
  }

  // DELETE /address-book/<BOOK_KEY>/pricelist/<PRICELIST_KEY>
  deleteBookItemPricelist(book_key: number, o: AddressBookItemPricelist): Observable<any> {
    let result: BehaviorSubject<any> = new BehaviorSubject(null);
    
    if (this._authService.isAuthenticated()) {
      // initialize data
      let url: string = ServiceConfiguration.addressBook.apiPricelistKey;
      url = url.replace('<BOOK_KEY>', book_key.toString());
      url = url.replace('<PRICELIST_KEY>', o.pricelist_key.toString());

      this._http.delete(url).subscribe(
        response => {
          console.log(response);
          // alert
          let alert: string = $localize`Ceník zákazníka/dopravce byl úspěšně odstraněn.`;
          this._notificationService.alert(alert, 'success', 4000);
          result.next(true);
        },
        error => {
          console.log(error);
          // alert
          let alert: string = $localize`Chyba při odstranění ceníku zákazníka/dopravce.`; 
          this._notificationService.alert(alert, 'error', 4000);
        }
      );
    }
    return result.asObservable();
  }

  // method for creating pricelist array
  private buildPricelistsArray(data: Array<any>): any {
    let pricelists: Array<AddressBookItemPricelist> = [];
    data.forEach(
      o => {
        let pricelist: AddressBookItemPricelist = this.buildPricelist(o);
        pricelists.push(pricelist);
      }
    );
    return pricelists;
  }

  // method for creating a single pricelist object
  private buildPricelist(o: any): AddressBookItemPricelist {
    let pricelist: AddressBookItemPricelist = new AddressBookItemPricelist();
    for (let key in o) {
      pricelist[key] = o[key];
    }
    return pricelist;
  }
  

  /*********************************************************/
  /* Address - favorites */
  /*********************************************************/
  // GET /address-book/favorite/
  getAddressesFavoriteAll(searchText: string, max: number = null): Observable<Array<AddressFavorite>> {
    let result: BehaviorSubject<Array<AddressFavorite>> = new BehaviorSubject(null);

    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      let url: string = ServiceConfiguration.addressBook.apiFavorite;
      
      // skip long strings <=> no requests
      if (searchText.length > 15) {
        result.next([]);
        return result.asObservable();
      }
      // check if text is empty(default) or atleast 1 char(filter)
      if (searchText.length > 0) {
        // to lower and add filter string
        searchText = searchText.toLowerCase();
        url += '?filter=' + searchText;
      }

      this._loadingFavorites = true;
      this._http.get(url).subscribe(
        response => {
          let favorites = this.buildFavoritesFromData(response);
          if (max) {
            // slice number of address to max constant value 5 (autocompleter usage)
            if (favorites.length > max) {
              favorites = favorites.slice(0, max);
            }
          }
          result.next(favorites);
          this._loadingFavorites = false;
        },
        error => {
          // handle error
          console.log(error);
          result.next([]);
          this._loadingFavorites = false;
        }
      );
    }

    return result.asObservable();
  }

  // GET only favorites! /address-book/favorite/
  getAddressesFavorite(searchText: string, max: number = null): Observable<Array<AddressFavorite>> {
    let result: BehaviorSubject<Array<AddressFavorite>> = new BehaviorSubject(null);

    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      let url: string = ServiceConfiguration.addressBook.apiFavorite;
      
      // skip long strings <=> no requests
      if (searchText.length > 15) {
        result.next([]);
        return result.asObservable();
      }
      // check if text is empty(default) or atleast 1 char(filter)
      if (searchText.length > 0) {
        // to lower and add filter string
        searchText = searchText.toLowerCase();
        url += '?filter=' + searchText;
      }

      this._loadingFavorites = true;
      this._http.get(url).subscribe(
        response => {
          let favorites = this.buildFavoritesFromData(response);
          // filter from warehouses and delivery lines
          favorites = favorites.filter(f => f.type != 'W' && f.type != 'L');
          if (max) {
            // slice number of address to max constant value 5 (autocompleter usage)
            if (favorites.length > max) {
              favorites = favorites.slice(0, max);
            }
          }
          result.next(favorites);
          this._loadingFavorites = false;
        },
        error => {
          // handle error
          console.log(error);
          result.next([]);
          this._loadingFavorites = false;
        }
      );
    }

    return result.asObservable();
  }

  // GET /address-book/favorite/?type=V,W
  getAddressesWarehouse(searchText: string = ''): Observable<Array<AddressFavorite>> {
    let result: BehaviorSubject<Array<AddressFavorite>> = new BehaviorSubject(null);

    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      let url: string = ServiceConfiguration.addressBook.apiFavorite + '?type=V,W';

      // skip long strings <=> no requests
      if (searchText.length > 15) {
        result.next([]);
        return result.asObservable();
      }
      // check if text is empty(default) or atleast 1 char(filter)
      if (searchText.length > 0) {
        // to lower and add filter string
        searchText = searchText.toLowerCase();
        url += '&filter=' + searchText;
      }

      this._loadingWarehouses = true;
      this._http.get(url).subscribe(
        response => {
          this._loadingWarehouses = false;
          let warehouses = this.buildFavoritesFromData(response);
          result.next(warehouses);
        },
        error => {
          // handle error
          console.log(error);
          this._loadingWarehouses = false;
          result.next([]);
        }
      );
    }

    return result.asObservable();
  }x

  // GET /address-book/favorite/?type=L
  getDeliveryLinesFull(searchText: string = ''): Observable<Array<AddressFavorite>> {
    let result: BehaviorSubject<Array<AddressFavorite>> = new BehaviorSubject(null);

    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      let url: string = ServiceConfiguration.addressBook.apiFavorite + '?type=L';
      
      // skip long strings <=> no requests
      if (searchText.length > 15) {
        result.next([]);
        return result.asObservable();
      }
      // check if text is empty(default) or atleast 1 char(filter)
      if (searchText.length > 0) {
        // to lower and add filter string
        searchText = searchText.toLowerCase();
        url += '&filter=' + searchText;
      }

      this._loadingLines = true;
      this._http.get(url).subscribe(
        response => {
          this._loadingLines = false;
          result.next(this.buildFavoritesFromData(response));
        },
        error => {
          // handle error
          console.log(error);
          this._loadingLines = false;
          result.next([]);
        }
      );
    }

    return result.asObservable();
  }

  // GET /address-book/favorite/<OUL_KEY>/inside?lat=<LAT>&lng=<LNG>
  checkInsideDeliveryLine(line: AddressFavorite, lat: number, lng: number): Observable<boolean> {
    let result: BehaviorSubject<boolean> = new BehaviorSubject(null);

    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      let url: string = ServiceConfiguration.addressBook.apiFavoriteInside;
      url = url.replace('<OUL_KEY>', line.oul_key.toString());
      url = url.replace('<LAT>', lat.toString());
      url = url.replace('<LNG>', lng.toString());
      
      this._http.get(url).subscribe(
        response => {
          result.next(true);
        },
        error => {
          // handle error
          console.log(error);
        }
      );
    }

    return result.asObservable();
  }

  // POST /address-book/favorite/
  createAddressFavorite(favorite: AddressFavorite): Observable<AddressFavorite> {
    let newFavorite: BehaviorSubject<AddressFavorite> = new BehaviorSubject(null);

    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      let url: string = ServiceConfiguration.addressBook.apiFavorite;

      this._http.post(url, favorite.apiObject).subscribe(
        response => {
          let favorite: AddressFavorite = this.buildFavorite(response);
          newFavorite.next(favorite);
          
          // success alert
          let alertSuccess: string = $localize`Adresa byla úspěšně vytvořena.`;
          this._notificationService.alert(alertSuccess, 'success', 4000);
        },
        error => {
          //handle error
          console.error(error);
          // success alert
          let alertError: string = $localize`Chyba při ukládání adresy do oblíbených.`;
          this._notificationService.alert(alertError, 'error', 4000);
        }
      );
    }

    return newFavorite.asObservable();
  }

  // PUT /address-book/favorite/<OUL_KEY>
  updateAddressFavorite(favorite: AddressFavorite): Observable<AddressFavorite> {
    let editFavorite: BehaviorSubject<AddressFavorite> = new BehaviorSubject(null);

    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      let url: string = ServiceConfiguration.addressBook.apiFavoriteKey;
      url = url.replace('<OUL_KEY>', favorite.oul_key.toString());

      this._http.put(url, favorite.apiObject).subscribe(
        response => {
          let favorite: AddressFavorite = this.buildFavorite(response);
          // success alert
          let alertSuccess: string = $localize`Adresa byla úspěšně upravena.`;
          this._notificationService.alert(alertSuccess, 'success', 4000);
          editFavorite.next(favorite);
        },
        error => {
          //handle error
          console.error(error);
          // success alert
          let alertError: string = $localize`Chyba při ukládání adresy.`;
          this._notificationService.alert(alertError, 'error', 4000);
        }
      );
    }

    return editFavorite.asObservable();
  }

  // DELETE /address-book/favorite/<OUL_KEY>
  deleteAddressFavorite(favorite: AddressFavorite): Observable<boolean> {
    let res: BehaviorSubject<any> = new BehaviorSubject(false);
    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      let url: string = ServiceConfiguration.addressBook.apiFavoriteKey;
      url = url.replace('<OUL_KEY>', favorite.oul_key.toString());

      this._http.delete(url).subscribe(
        response => {
          this._notificationService.alert(
            $localize`Adresa byla odstraněna.`, 'success', 4000
          );
          res.next(true);
        },
        error => {
          //handle error
          console.error(error);
          this._notificationService.alert(
            $localize`Chyba při odstranění adresy - kontaktujte podporu.`, 'error', 4000
          );
        }
      );
    }
    return res.asObservable();
  }

  // method for creating favorites array
  private buildFavoritesFromData(data: Array<any>): any {
    let favorites: Array<AddressFavorite> = [];
    data.forEach(
      f => {
        let favorite: AddressFavorite = this.buildFavorite(f);
        favorites.push(favorite);
      }
    );
    return favorites;
  }

  // method for creating a single favorite address object
  private buildFavorite(f: any): AddressFavorite {
    let favorite: AddressFavorite = new AddressFavorite();
    for (let key in f) {
      favorite[key] = f[key];
    }
    return favorite;
  }


  /* VIES for company tin */
  // method for getting vies data -> using PHP script (because api for vies is quiet complicated in angular)
  getDataFromVies(vatNumber: string): Observable<any> {
    let result: BehaviorSubject<any> = new BehaviorSubject(null);
    
    if (vatNumber && (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated()))) {
      this._loadingVies = true;
      this._http.get(this._layoutServ.staticContentResource + 'truckagenda/company_new_get_vies.php/?tin=' + vatNumber)
        .subscribe(
          response => {
            result.next(response);
            console.log(response);
            this._loadingVies = false;
          },
          error => {
            console.log(error);
            this._loadingVies = false;
          }
        );
    }

    return result.asObservable();
  }

  /* ARES for CZ company tin */
  // method for getting vies data -> using PHP script (because api for vies is quiet complicated in angular)
  getDataFromAres(cin: string): Observable<any> {
    let result: BehaviorSubject<any> = new BehaviorSubject(null);
    
    if (cin && (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated()))) {
      this._loadingVies = true;
      let url: string = this._layoutServ.staticContentResource + 'truckagenda/company_new_get_ares_v2.php/?ico=' + cin;
      this._http.get(url).subscribe(
        response => {
          result.next(response);
          console.log(response);
          this._loadingVies = false;
        },
        error => {
          console.log(error);
          this._loadingVies = false;
        }
      );
    }

    return result.asObservable();
  }

  getDataFromAres2(cin: string): Observable<any> {
    // let result: BehaviorSubject<any> = new BehaviorSubject(null);
    if (cin && (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated()))) {
      let url: string = 'https://ares.gov.cz/ekonomicke-subjekty-v-be/rest/ekonomicke-subjekty/<CIN>';
      url = url.replace('<CIN>', cin);
      return this._httpClient.get(url);
      
      // this._loadingVies = true;
      // this._http.get(url).subscribe(
      //   response => {
      //     result.next(response);
      //     console.log(response);
      //     this._loadingVies = false;
      //   },
      //   error => {
      //     console.log(error);
      //     this._loadingVies = false;
      //   }
      // );
    }
  }


  /* DPH */
  // method for getting tax payers trustiness -> using PHP script, because angular soap api is ugly =(
  getTaxTrustiness(cin: string): Observable<any> {
    let result: BehaviorSubject<any> = new BehaviorSubject(null);
    
    if (cin && (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated()))) {
      this._loadingTax = true;
      this._http.get(this._layoutServ.staticContentResource + 'truckagenda/company_soap_dph.php/?cin=' + cin)
        .subscribe(
          response => {
            console.log(response);
            result.next(response);
            this._loadingTax = false;
          },
          error => {
            console.log(error);
            this._loadingTax = false;
          }
        );
    }

    return result.asObservable();
  }

  // DELETE /address-book/<BOOK_KEY>/token
  // deleting external token for netOrder creating (~ external obligation)
  deleteNewObligationToken(book_key: number): Observable<any>  {
    let result: BehaviorSubject<any> = new BehaviorSubject(null);

    if (book_key && this._authService.isAuthenticated()) {
      let url: string = ServiceConfiguration.obligations.apiExternalObligationDelete;
      url = url.replace('<BOOK_KEY>', book_key.toString());

      this._http.delete(url).subscribe(
        response => {
          // console.log(response);
          let alert: string = $localize`Povolení netObjednávky bylo odstraněno.`;
          this._notificationService.alert(alert, 'success', 4000);
          // observable next
          result.next(true);
        },
        error => {
          // handle error
          let alert: string = $localize`Chyba při odstranění povolení netObjednávky.`;
          this._notificationService.alert(alert, 'error', 4000);
          console.log(error);
        }
      );
    }

    return result.asObservable();
  }
}
